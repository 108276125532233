<script lang="ts" setup>
import type { GameType } from "@/types";

const { t } = useT();

const props = withDefaults(
	defineProps<{
		type?: GameType;
		route?: string;
		row?: number;
		title: string;
	}>(),
	{
		row: 1
	}
);

const gridView = computed(() => props.type?.includes("grid"));
</script>

<template>
	<div :class="['list-items-wrapper', `section-${type}`]">
		<MHomeSectionHeader :title="t(title)" :route="route" />
		<div v-if="gridView" class="grid-items">
			<slot />
		</div>
		<AHorizontalScroll v-else shadowEffect>
			<div class="box-items">
				<slot />
			</div>
		</AHorizontalScroll>
	</div>
</template>

<style lang="scss" scoped>
div.list-items-wrapper {
	display: flex;
	flex-direction: column;
	gap: 16px;
	overflow: hidden;
}

.box-items {
	display: grid;
	grid-auto-flow: column dense;
	gap: 8px;
	grid-template-rows: repeat(v-bind(row), 1fr);

	&:deep(img) {
		pointer-events: none;
	}
}

@include media-breakpoint-down(md) {
	div.scroll-container {
		width: calc(100% + 32px);
		margin-left: -16px;
		padding-left: 16px;
		padding-right: 16px;
	}
}

.grid-items {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(114px, 1fr));
	gap: 8px;
	@include media-breakpoint-up(md) {
		grid-template-columns: repeat(auto-fit, minmax(163px, 1fr));
	}

	:deep(.game-card-wrap) {
		width: 100%;
		.game-card .image-block {
			width: 100%;
		}
	}
}
</style>
